import { useEffect } from 'react';
import { connect } from 'react-redux';
import {
	exchangeSelector,
	accountSelector,
	tokenSelector,
	web3Selector,
	etherBalanceSelector,
	tokenBalanceSelector,
	exchangeEtherBalanceSelector,
	exchangeTokenBalanceSelector,
	balancesLoadingSelector,
	etherDepositAmountSelector,
	etherWithdrawAmountSelector,
	tokenDepositAmountSelector,
	tokenWithdrawAmountSelector,
} from '../store/selectors';
import {
	etherDepositAmountChanged,
	etherWithdrawAmountChanged,
	tokenDepositAmountChanged,
	tokenWithdrawAmountChanged,
} from '../store/actions';
import { loadBalances, depositEther, withdrawEther, depositToken, withdrawToken } from '../store/interactions';
import Spinner from './Spinner';
import { Tabs, Tab } from 'react-bootstrap';

const renderDashboard = (
	etherBalance,
	exchangeEtherBalance,
	tokenBalance,
	exchangeTokenBalance,
	dispatch,
	web3,
	exchange,
	token,
	account,
	etherDepositAmount,
	etherWithdrawAmount,
	tokenDepositAmount,
	tokenWithdrawAmount,
) => {
	return (
		<Tabs defaultActiveKey='deposit' className='bg-dark text-white'>
			<Tab eventKey='deposit' title='Deposit' className='bg-dark'>
				<table className='table table-dark table-sm small'>
					<thead>
						<tr>
							<th>Token</th>
							<th>Wallet</th>
							<th>Exchange</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th>ETH</th>
							<th>{etherBalance}</th>
							<th>{exchangeEtherBalance}</th>
						</tr>
						<tr>
							<th>XTK</th>
							<th>{tokenBalance}</th>
							<th>{exchangeTokenBalance}</th>
						</tr>
					</tbody>
				</table>
				<div className='form-wrapper'>
					<form
						id='ether-deposit'
						className='row'
						onSubmit={(event) => {
							event.preventDefault();
							depositEther(dispatch, exchange, web3, etherDepositAmount, account);
						}}
					>
						<div className='col-12 col-sm pr-sm-2'>
							<input
								type='text'
								placeholder='ETH Amount'
								onChange={(e) => dispatch(etherDepositAmountChanged(e.target.value))}
								className='form-control form-control-sm bg-dark text-white'
								required
							/>
						</div>
						<div className='col-12 col-sm-auto pl-sm-0'>
							<button type='submit' className='btn btn-primary btn-block btn-sm'>
								Deposit
							</button>
						</div>
					</form>
					<form
						id='token-deposit'
						className='row'
						onSubmit={(event) => {
							event.preventDefault();
							depositToken(dispatch, exchange, token, web3, tokenDepositAmount, account);
						}}
					>
						<div className='col-12 col-sm pr-sm-2'>
							<input
								type='text'
								placeholder='XTK Amount'
								onChange={(e) => dispatch(tokenDepositAmountChanged(e.target.value))}
								className='form-control form-control-sm bg-dark text-white'
								required
							/>
						</div>
						<div className='col-12 col-sm-auto pl-sm-0'>
							<button type='submit' className='btn btn-primary btn-block btn-sm'>
								Deposit
							</button>
						</div>
					</form>
				</div>
			</Tab>
			<Tab eventKey='withdraw' title='Withdraw' className='bg-dark'>
				<table className='table table-dark table-sm small'>
					<thead>
						<tr>
							<th>Token</th>
							<th>Wallet</th>
							<th>Exchange</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th>ETH</th>
							<th>{etherBalance}</th>
							<th>{exchangeEtherBalance}</th>
						</tr>
						<tr>
							<th>XTK</th>
							<th>{tokenBalance}</th>
							<th>{exchangeTokenBalance}</th>
						</tr>
					</tbody>
				</table>
				<div className='form-wrapper'>
					<form
						id='ether-withdraw'
						className='row'
						onSubmit={(event) => {
							event.preventDefault();
							withdrawEther(dispatch, exchange, web3, etherWithdrawAmount, account);
						}}
					>
						<div className='col-12 col-sm pr-sm-2'>
							<input
								type='text'
								placeholder='ETH Amount'
								onChange={(e) => dispatch(etherWithdrawAmountChanged(e.target.value))}
								className='form-control form-control-sm bg-dark text-white'
								required
							/>
						</div>
						<div className='col-12 col-sm-auto pl-sm-0'>
							<button type='submit' className='btn btn-primary btn-block btn-sm'>
								Withdraw
							</button>
						</div>
					</form>
					<form
						id='token-withdraw'
						className='row'
						onSubmit={(event) => {
							event.preventDefault();
							withdrawToken(dispatch, exchange, token, web3, tokenWithdrawAmount, account);
						}}
					>
						<div className='col-12 col-sm pr-sm-2'>
							<input
								type='text'
								placeholder='XTK Amount'
								onChange={(e) => dispatch(tokenWithdrawAmountChanged(e.target.value))}
								className='form-control form-control-sm bg-dark text-white'
								required
							/>
						</div>
						<div className='col-12 col-sm-auto pl-sm-0'>
							<button type='submit' className='btn btn-primary btn-block btn-sm'>
								Withdraw
							</button>
						</div>
					</form>
				</div>
			</Tab>
		</Tabs>
	);
};

const Balance = ({
	dispatch,
	exchange,
	web3,
	token,
	account,
	showForm,
	etherBalance,
	exchangeEtherBalance,
	tokenBalance,
	exchangeTokenBalance,
	etherDepositAmount,
	etherWithdrawAmount,
	tokenDepositAmount,
	tokenWithdrawAmount,
}) => {
	useEffect(() => {
		loadBlockchainData(dispatch, exchange, web3, token, account);
	}, []);

	const loadBlockchainData = async (dispatch, exchange, web3, token, account) => {
		await loadBalances(dispatch, web3, exchange, token, account);
	};

	return (
		<div className='card bg-dark text-white'>
			<div className='card-header'>Balance</div>
			<div className='card-body balance'>
				{showForm ? (
					renderDashboard(
						etherBalance,
						exchangeEtherBalance,
						tokenBalance,
						exchangeTokenBalance,
						dispatch,
						web3,
						exchange,
						token,
						account,
						etherDepositAmount,
						etherWithdrawAmount,
						tokenDepositAmount,
						tokenWithdrawAmount,
					)
				) : (
					<Spinner type='table' />
				)}
			</div>
		</div>
	);
};

function mapStateToProps(state) {
	const balancesLoading = balancesLoadingSelector(state);

	return {
		web3: web3Selector(state),
		account: accountSelector(state),
		exchange: exchangeSelector(state),
		token: tokenSelector(state),
		etherBalance: etherBalanceSelector(state),
		tokenBalance: tokenBalanceSelector(state),
		exchangeEtherBalance: exchangeEtherBalanceSelector(state),
		exchangeTokenBalance: exchangeTokenBalanceSelector(state),
		balancesLoading,
		showForm: !balancesLoading,
		etherDepositAmount: etherDepositAmountSelector(state),
		etherWithdrawAmount: etherWithdrawAmountSelector(state),
		tokenDepositAmount: tokenDepositAmountSelector(state),
		tokenWithdrawAmount: tokenWithdrawAmountSelector(state),
	};
}

export default connect(mapStateToProps)(Balance);
